








import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import PageProfile from '@improve/common-components/src/components/user/PageProfile.vue';

@Component({
  name: 'CompleteProfile',
  components: {
    PageProfile
  }
})
export default class CompleteProfile extends Vue {
}
